import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from 'react-router-dom';
import SimpleReactLightbox from "simple-react-lightbox";
import  ThemeContext  from "./config/context/ThemeContext";

ReactDOM.render(
	<React.StrictMode>
            <SimpleReactLightbox>
                <BrowserRouter basename='/'>
                    <ThemeContext>
                        <App />
                    </ThemeContext>
                </BrowserRouter>
            </SimpleReactLightbox>
	</React.StrictMode>,
  document.getElementById("root")
);
