import {format} from 'date-fns';
import { ColumnFilter } from './ColumnFilter';
import {
	convertDateAndTimeToView,
	getStatusOfOrder, getStatusOfPayment,
} from "../../utils/SharedFunctions";
export const COLUMNS = [
	{
		Header : 'Id',
		Footer : 'Id',
		accessor: 'id',
		Filter: ColumnFilter,
		//disableFilters: true,
	},
	{
		Header : 'First Name',
		Footer : 'First Name',
		accessor: 'first_name',
		Filter: ColumnFilter,
	},
	{
		Header : 'Last Name',
		Footer : 'Last Name',
		accessor: 'last_name',
		Filter: ColumnFilter,
	},
	{
		Header : 'Email Id',
		Footer : 'Email Id',
		accessor: 'email',
		Filter: ColumnFilter,
	},
	{
		Header : 'Date of  Birth',
		Footer : 'Date of  Birth',
		accessor: 'date_of_birth',
		Cell: ({ value }) => {return format(new Date(value), 'dd/mm/yyyy')},
		Filter: ColumnFilter,
	},
	{
		Header : 'Country',
		Footer : 'Country',
		accessor: 'country',
		Filter: ColumnFilter,
	},
	{
		Header : 'Phone',
		Footer : 'Phone',
		accessor: 'phone',
		Filter: ColumnFilter,
	},
]



export const orders_col = [
	{
		Header : 'Date of the order',
		Footer : 'Date of the order',
		accessor: 'create_date',
		Filter: ColumnFilter,
		Cell: ({ value }) => {return  convertDateAndTimeToView(value)},
	},
	{
		Header : 'Id',
		Footer : 'Id',
		accessor: 'id',
		Filter: ColumnFilter,
		//disableFilters: true,
	},
	{
		Header : 'License plate',
		Footer : 'License plate',
		accessor: 'license_plate',
		Filter: ColumnFilter,
	},

	{
		Header : 'City',
		Footer : 'City',
		accessor: 'city',
		Filter: ColumnFilter,
	},

	{
		Header : 'Verified',
		Footer : 'Verified',
		accessor: 'verified',
		Filter: ColumnFilter,
		Cell: ({ value }) => {return value === 0? "No": "Yes"},
	},

	{
		Header : 'Payment status',
		Footer : 'Payment status',
		accessor: 'payment_status',
		Filter: ColumnFilter,
		Cell: ({ value }) => {return getStatusOfPayment(value)},
	},
	{
		Header : 'Status',
		Footer : 'Status',
		accessor: 'status',
		Cell: ({ value }) => {return getStatusOfOrder(value)},
		Filter: ColumnFilter,
	},
	{
		Header : 'Link to our website',
		Footer : 'Link to our website',
		accessor: 'car_code',
		Filter: ColumnFilter,
		Cell: ({ value }) => {return <a target="_blank" href={"https://qrvehicle.com/vehicle-info/"+ value}> link</a> },
	},
	{
		Header : 'Url',
		Footer : 'Url',
		accessor: 'url',
		Filter: ColumnFilter,
		Cell: ({ value }) => {return <a target="_blank" href={value}>{value}</a> },
	},
	{
		Header : 'Payment intent',
		Footer : 'Payment intent',
		accessor: 'payment_intent',
		Filter: ColumnFilter,
	},
	{
		Header : 'Payment ref',
		Footer : 'Payment ref',
		accessor: 'payment_ref',
		Filter: ColumnFilter,
	},
]
