import {apiGet, apiPost, apiPut} from "./Api";
import {appUrls} from "../config/Constants";
import {loginAgain, LoginAgain} from "../utils/SharedFunctions";


export async function step1AuthCall(data) {
    return apiPost(appUrls.backend_api+"request_code", data).then(result => {
        return result;
    }).catch(err => {
        throw err;
    });
}

export async function step2AuthCall(data) {
    return apiPost(appUrls.backend_api+"auth_based_on_code", data).then(result => {
        return result;
    }).catch(err => {
        throw err;
    });
}




export async function getAllOrderCall() {
    return apiGet(appUrls.backend_api+"orders").then(result => {
        return result;
    }).catch(err => {
        loginAgain(err)
        throw err;
    });
}

export async function getOrderStickerCall(id) {
    return apiGet(appUrls.backend_api+"order/"+id).then(result => {
        return result;
    }).catch(err => {
        loginAgain(err)
        throw err;
    });
}


export async function getPaymentStatusAdminCall(code) {
    return apiGet(appUrls.backend_api+"get_payment_status_admin/"+code).then(result => {
        return result;
    }).catch(err => {
        loginAgain(err)
        throw err;
    });
}


export async function updateOrderDeliveryLinkCall(data) {
    return apiPut(appUrls.backend_api+"update_order_delivery_link", data).then(result => {
        return result;
    }).catch(err => {
        throw err;
    });
}

export async function updateOrderStatusCall(data) {
    return apiPut(appUrls.backend_api+"update_order_status", data).then(result => {
        return result;
    }).catch(err => {
        throw err;
    });
}

export async function updateCustomerInfoCall(data) {
    return apiPut(appUrls.backend_api+"update_customer_info", data).then(result => {
        return result;
    }).catch(err => {
        throw err;
    });
}


export async function createCarAdminCall(data) {
    return apiPost(appUrls.backend_api+"add_vehicle_admin", data).then(result => {
        return result;
    }).catch(err => {
        throw err;
    });
}

