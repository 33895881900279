import React, {useEffect, useMemo, useState} from 'react';
import {useTable, useGlobalFilter, useSortBy, useFilters, usePagination} from 'react-table';
import '../../../components/FilteringTable/filtering.css';

import {toast} from "react-toastify";
import {Modal} from "react-bootstrap";

import { orders_col} from "../../../components/FilteringTable/Columns";
import {GlobalFilter} from "../../../components/FilteringTable/GlobalFilter";
import {appToastMessages, toastOptions} from "../../../config/Constants";
import {
    getAllOrderCall,
    getOrderStickerCall, getPaymentStatusAdminCall, updateCustomerInfoCall,
    updateOrderDeliveryLinkCall,
    updateOrderStatusCall
} from "../../../Api/ApiCalls";
import {getStatusOfPayment, hasAValue} from "../../../utils/SharedFunctions";
import Swal from "sweetalert";


export default function DomainsOverview() {
    const [data, setData] = useState([])
    const [columns, setColumns] = useState([])
    const [showModal, setShowModal] = useState(false)
    const [showEdit, setShowEdit] = useState(false)
    const [editable, setEditable] = useState(false)



    const [item, setItem] = useState(undefined)
    const [orderDeliveryLink, setOrderDeliveryLink] = useState(undefined)
    const [address, setAddress] = useState(undefined)
    const [postal_code, setPostal_code] = useState(undefined)
    const [city, setCity] = useState(undefined)
    const [license_plate, setLicense_plate] = useState(undefined)
    const [owner_email, setOwner_email] = useState(undefined)
    const [url, setUrl] = useState(undefined)


    useEffect(() => {
        setColumns(orders_col)
        getAllOrder()
    }, [])


    const tableInstance = useTable({
        columns,
        data,
        initialState: {pageIndex: 0}
    }, useFilters, useGlobalFilter, useSortBy, usePagination)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        state,
        page,
        gotoPage,
        pageCount,
        pageOptions,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        setGlobalFilter,
    } = tableInstance


    const {globalFilter, pageIndex} = state


    return (
        <>
            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">Orders</h4>
                    {/*<a className="btn btn-secondary" onClick={() => openModalAdd()}>+ Add new domain</a>*/}

                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter}/>
                        <table {...getTableProps()} className="table dataTable display">
                            <thead>
                            {headerGroups.map(headerGroup => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                                            {column.render('Header')}
                                            <span className="ml-1">
                                                {column.isSorted ? (
                                                        column.isSortedDesc ?
                                                            <i className="fa fa-arrow-down ms-2 fs-14"
                                                               style={{opacity: '0.7'}}/>
                                                            :
                                                            <i className="fa fa-arrow-up ms-2 fs-14"
                                                               style={{opacity: '0.7'}}/>
                                                    )
                                                    :
                                                    (<i className="fa fa-sort ms-2 fs-14"
                                                        style={{opacity: '0.3'}}/>)}
                                            </span>
                                        </th>
                                    ))}
                                </tr>
                            ))}
                            </thead>
                            <tbody {...getTableBodyProps()}>

                            {page.map((row) => {
                                prepareRow(row)
                                return (
                                    <tr {...row.getRowProps()}>
                                        {row.cells.map((cell) => {
                                            return <td {...cell.getCellProps()} className={"cursor-pointer"}
                                                       onClick={() => openModalEdit(row.original)}> {cell.render('Cell')} </td>
                                        })}

                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                        {/* This is only for footer if u require */}
                        <div className="d-flex justify-content-between">
							<span>
								Page{' '}
                                <strong>
									{pageIndex + 1} of {pageOptions.length}
								</strong>{''}
							</span>
                            <span className="table-index">
								Go to page : {' '}
                                <input type="number"
                                       className="ml-2"
                                       defaultValue={pageIndex + 1}
                                       onChange={e => {
                                           const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0
                                           gotoPage(pageNumber)
                                       }}
                                />
							</span>
                        </div>
                        <div className="text-center mb-3">
                            <div className="filter-pagination  mt-3">
                                <button className=" previous-button" onClick={() => gotoPage(0)}
                                        disabled={!canPreviousPage}>{'<<'}</button>

                                <button className="previous-button" onClick={() => previousPage()}
                                        disabled={!canPreviousPage}>
                                    Previous
                                </button>
                                <button className="next-button" onClick={() => nextPage()} disabled={!canNextPage}>
                                    Next
                                </button>
                                <button className=" next-button" onClick={() => gotoPage(pageCount - 1)}
                                        disabled={!canNextPage}>{'>>'}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal className="modal fade" size={"xl"} show={showModal} onHide={setShowModal}>
                <div className="" role="document">
                    <div className="">
                        {hasAValue(item) &&  <form>
                            <div className="modal-header">
                                {hasAValue(item) && <h4 className="modal-title fs-20">Order number {item.id}</h4>}
                                <button type="button" className="btn-close" onClick={() => setShowModal(false)}
                                        data-dismiss="modal"></button>
                            </div>
                          <div className="modal-body">

                              {/*Order info*/}
                                <div className="card border-top shadow">
                                    <div className="card-header border-0 pb-0">
                                        <h2 className="card-title">Order overview</h2>
                                    </div>
                                    <div className="card-body pb-0">
                                        <ul className="list-group list-group-flush">

                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-3"}>Verified</strong>
                                                <span
                                                    className={"col-9 fw-bold text-primary"}>{(hasAValue(item.verified))&& (item.verified === 0? "No" : "Yes" )}</span>
                                            </li>

                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-3"}>Payment status</strong>
                                                <span
                                                    className={"col-9 fw-bold text-primary"}>{hasAValue(item.payment_status) && getStatusOfPayment(item.payment_status)}</span>
                                            </li>

                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-3"}>Payment intent</strong>
                                                <span
                                                    className={"col-9 fw-bold text-primary"}>{hasAValue(item.payment_intent) && item.payment_intent}</span>
                                            </li>

                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-3"}>Payment ref</strong>
                                                <span
                                                    className={"col-9 fw-bold text-primary"}>{hasAValue(item.payment_ref) && item.payment_ref}</span>
                                            </li>

                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-3"}>Order status</strong>

                                                <span className={"col-9 fw-bold text-primary"}><select
                                                    className="form-control form-control-sm fw-bold text-primary"
                                                    defaultValue={hasAValue(item.status) && item.status}
                                                    required
                                                    onChange={(val) => {
                                                        updateOrderStatus(item, val.target.value)
                                                    }}
                                                >
                                                    <option value={"ready"}>Ready</option>
                                                    <option value={"inprogress"}>In progress</option>
                                                    <option value={"sent"}>Sent</option>
                                                </select></span>
                                            </li>

                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-3"}>Link to our website</strong>
                                                <span
                                                    className={"col-9 fw-bold text-primary"}><a className={"fw-bold text-primary"} target="_blank" href={"https://qrvehicle.com/vehicle-info/"+ item.car_code}> {"https://qrvehicle.com/vehicle-info/"+ item.car_code}</a> </span>
                                            </li>

                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-3"}>Sticker</strong>
                                                <span
                                                    className={"col-9 fw-bold text-primary"}><a  className={"fw-bold text-primary cursor-pointer"}  download={item.id} onClick={() => {getOrderSticker(item.id)}}> Show sticker</a> </span>
                                            </li>

                                            <li className="list-group-item d-flex px-0 justify-content-between row">
                                                <strong className={"col-3"}>Fix Sticker</strong>
                                                <span
                                                    className={"col-9 fw-bold text-danger"}><a  className={"fw-bold text-primary cursor-pointer"}  download={item.id} onClick={() => {getPaymentStatusAdmin(item.car_code)}}> Fix Sticker</a> </span>
                                            </li>

                                        </ul>
                                    </div>
                                </div>

                              {/*Customer info*/}
                              <div className="card border-top shadow">
                                  <div className="card-header border-0 pb-0">
                                      <h2 className="card-title">Customer info</h2>
                                      <span
                                          className={"col-9 fw-bold text-primary ps-2"}>
                                                <input
                                                    type="checkbox"
                                                    checked={editable}
                                                    className={editable ? "form-check form-control-sm fw-bold text-primary" : "form-check bg-light form-control-sm fw-bold text-primary"}
                                                    required
                                                    onChange={()=> setEditable(!editable)}
                                                /></span>
                                  </div>
                                  <div className="card-body pb-0">
                                      <ul className="list-group list-group-flush">
                                          <li className="list-group-item d-flex px-0 justify-content-between row">
                                              <strong className={"col-3"}>Street</strong>
                                              <span
                                                  className={"col-9 fw-bold text-primary"}><input
                                                  className={editable ? "form-control form-control-sm fw-bold text-primary" : "bg-light form-control form-control-sm fw-bold"}
                                                  disabled={!editable}
                                                  defaultValue={address}
                                                  required
                                                  onChange={(val) => {
                                                      setAddress(val.target.value)
                                                  }}
                                              >
                                                </input></span>

                                          </li>
                                          <li className="list-group-item d-flex px-0 justify-content-between row">
                                              <strong className={"col-3"}>Postcode</strong>
                                              <span
                                                  className={"col-9 fw-bold text-primary"}><input
                                                  className={editable ? "form-control form-control-sm fw-bold text-primary" : "bg-light form-control form-control-sm fw-bold "}
                                                  disabled={!editable}
                                                  defaultValue={postal_code}
                                                  required
                                                  onChange={(val) => {
                                                      setPostal_code(val.target.value)
                                                  }}
                                              >
                                                </input></span>
                                          </li>
                                          <li className="list-group-item d-flex px-0 justify-content-between row">
                                              <strong className={"col-3"}>City</strong>
                                              <span
                                                  className={"col-9 fw-bold text-primary"}><input
                                                  className={editable ? "form-control form-control-sm fw-bold text-primary" : "bg-light form-control form-control-sm fw-bold "}
                                                  disabled={!editable}
                                                  defaultValue={city}
                                                  required
                                                  onChange={(val) => {
                                                      setCity(val.target.value)
                                                  }}
                                              >
                                                </input></span>
                                          </li>


                                          <li className="list-group-item d-flex px-0 justify-content-between row">
                                              <strong className={"col-3"}>License plate</strong>
                                              <span
                                                  className={"col-9 fw-bold text-primary"}><input
                                                  className={editable ? "form-control form-control-sm fw-bold text-primary" : "bg-light form-control form-control-sm fw-bold"}
                                                  disabled={!editable}
                                                  defaultValue={license_plate}
                                                  required
                                                  onChange={(val) => {
                                                      setLicense_plate(val.target.value)
                                                  }}
                                              >
                                                </input></span>
                                          </li>

                                          <li className="list-group-item d-flex px-0 justify-content-between row">
                                              <strong className={"col-3"}>Email</strong>
                                              <span
                                                  className={"col-9 fw-bold text-primary"}><input
                                                  className={editable ? "form-control form-control-sm fw-bold text-primary" : "bg-light form-control form-control-sm fw-bold"}
                                                  disabled={!editable}
                                                  defaultValue={owner_email}
                                                  required
                                                  onChange={(val) => {
                                                      setOwner_email(val.target.value)
                                                  }}
                                              >
                                                </input></span>
                                          </li>


                                          <li className="list-group-item d-flex px-0 justify-content-between row">
                                              <strong className={"col-3"}>Link to the car</strong>
                                              <span
                                                  className={"col-8 fw-bold text-primary"}><textarea
                                                  className={editable ? "form-control form-control-sm fw-bold text-primary" : "bg-light form-control form-control-sm fw-bold"}
                                                  disabled={!editable}
                                                  defaultValue={url}
                                                  required
                                                  onChange={(val) => {
                                                      setUrl(val.target.value)
                                                  }}
                                              >
                                                </textarea></span>
                                              <span className={"col-1 fw-bold text-primary"}><a  className={"fw-bold text-primary"} target="_blank" href={item.url}> Go to link</a> </span>
                                          </li>
                                          <li className="list-group-item d-flex px-0 justify-content-end row">
                                              <div className="col-3">
                                                  <button type="button" className="btn text-primary" onClick={() => {updateCustomerInfo(item)}}>Update info</button>
                                              </div>
                                          </li>

                                      </ul>
                                  </div>
                              </div>

                              {/*Delivery info*/}
                              <div className="card border-top shadow">
                                  <div className="card-header border-0 pb-0">
                                      <h2 className="card-title">Delivery info</h2>
                                  </div>
                                  <div className="card-body pb-0">
                                      <ul className="list-group list-group-flush">
                                          <li className="list-group-item d-flex px-0 justify-content-between row">
                                              <strong className={"col-3"}>Delivery link</strong>
                                              <span
                                                  className={"col-md-7 col-sm-10 fw-bold text-primary"}><input
                                                  className="form-control form-control-sm fw-bold text-primary"
                                                  defaultValue={orderDeliveryLink && orderDeliveryLink}
                                                  required
                                                  onChange={(val) => {
                                                      setOrderDeliveryLink(val.target.value)
                                                  }}
                                              >
                                                </input></span>
                                              <div className="col-md-2 col-sm-10">
                                                  <button type="button" className="btn text-primary" onClick={() => {updateOrderDeliveryLink(item)}}>Update delivery</button>
                                              </div>
                                          </li>

                                      </ul>
                                  </div>
                              </div>
                            </div>

                            <div className="modal-footer">

                                <button type="button" onClick={() => setShowModal(false)}
                                        className="btn btn-warning">Close
                                </button>
                            </div>
                        </form>}

                    </div>
                </div>
            </Modal>
        </>
    )

    function openModalEdit(item) {
        setItem(item)
        setOrderDeliveryLink(item.delivery_link)
        setShowModal(true)
        setShowEdit(true)
        setAddress(item.address)
        setUrl(item.url)
        setCity(item.city)
        setPostal_code(item.postal_code)
        setOwner_email(item.owner_email)
        setLicense_plate(item.license_plate)
        setEditable(false)
    }


   function getAllOrder(){
        getAllOrderCall().then(orders => {
            setData(orders)
        }).catch(error => {
            toast.error(error.response.data.error, toastOptions);
        })
    }

    function getOrderSticker(id){
        getOrderStickerCall(id).then(order => {
            if(hasAValue(order.sticker)){
                window.open('data:image/png;base64,' + arrayBufferToBase64(order.sticker.data))
            }else{
                toast.error(appToastMessages.not_paid_order, toastOptions);
            }
        }).catch(error => {
            toast.error(error.response.data.error, toastOptions);
        })
    }


    function getPaymentStatusAdmin(code){
        getPaymentStatusAdminCall(code).then(order => {
            toast.success(appToastMessages.done, toastOptions);
        }).catch(error => {
            toast.error(error.response.data.error, toastOptions);
        })
    }



    function arrayBufferToBase64(buffer) {
        let binary = '';
        let bytes = new Uint8Array(buffer);
        let len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return (binary);
    }

    function updateOrderDeliveryLink(order){
        if(hasAValue(orderDeliveryLink)){
            Swal({
                title: "Are you sure?",
                text: "You want to change the status!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then((response) => {
                if (response) {
                    let data = {id: order.id, link: orderDeliveryLink}
                    updateOrderDeliveryLinkCall(data).then(res => {
                        getAllOrder()
                        toast.success(res.data.message, toastOptions);
                    }).catch(error => {
                        toast.error(error.response.data.error, toastOptions);
                    })
                } else {
                    toast.error(appToastMessages.action_canceled, toastOptions);
                }
            })
        }else{
            toast.error(appToastMessages.all_fields_are_required, toastOptions);
        }
    }

    function updateOrderStatus(order, status){
        Swal({
            title: "Are you sure?",
            text: "You want to change the status!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((response) => {
            if (response) {
                let data = {id: order.id, status: status}
                updateOrderStatusCall(data).then(res => {
                    getAllOrder()
                    toast.success(res.data.message, toastOptions);
                }).catch(error => {
                    toast.error(error.response.data.error, toastOptions);
                })
            } else {
                toast.error(appToastMessages.action_canceled, toastOptions);
            }
        })
    }

    function updateCustomerInfo(order){
        Swal({
            title: "Are you sure?",
            text: "You want to change the status!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((response) => {
            if (response) {
                let data = {id: order.id, address, url, city, postal_code, owner_email, license_plate}
                updateCustomerInfoCall(data).then(res => {
                    getAllOrder()
                    toast.success(res.data.message, toastOptions);
                }).catch(error => {
                    toast.error(error.response.data.error, toastOptions);
                })
            } else {
                toast.error(appToastMessages.action_canceled, toastOptions);
            }
        })
    }

}
