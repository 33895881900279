import React, {useEffect, useMemo, useState} from 'react';
import '../../../components/FilteringTable/filtering.css';
import {createCarAdminCall, updateOrderDeliveryLinkCall} from "../../../Api/ApiCalls";
import {toast} from "react-toastify";
import {toastOptions} from "../../../config/Constants";
import Swal from "sweetalert2";



export default function PlaceAnOrder() {

    const [email, setEmail] = useState(undefined)
    const [address, setAddress] = useState(undefined)
    const [postal_code, setPostal_code] = useState(undefined)
    const [city, setCity] = useState(undefined)
    const [license_plate, setLicense_plate] = useState(undefined)
    const [url, setUrl] = useState(undefined)
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!loading) {
            Swal.close();
        }
    }, [loading]);


    return (
        <>



            <div className="card">
                <div className="card-header">
                    <h4 className="card-title">Place an order</h4>
                    {/*<a className="btn btn-secondary" onClick={() => openModalAdd()}>+ Add new domain</a>*/}

                </div>
                <div className="card-body">

                        <i className="flaticon-cancel-12 close"></i>
                        <div className="add-contact-box">
                            <div className="add-contact-content">
                                <div className={"row"}>

                                    <div className="form-group mb-3 col-sm-10 col-md-6">
                                        <label className="text-black font-w500">Kenteken</label>
                                        <div className="contact-name">
                                            <input type="text" className="form-control text-uppercase"
                                                   name="kenteken" required="required"
                                                   onChange={(val) => setLicense_plate(val.target.value.toString().toUpperCase())}
                                                   maxLength={6}
                                            />
                                            <span className="validation-text"></span>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 col-sm-10 col-md-6">
                                        <label className="text-black font-w500">Url</label>
                                        <div className="contact-name">
                                            <input type="text" className="form-control"
                                                   name="url" required="required"
                                                   defaultValue={url}
                                                   maxLength={400}
                                                   onChange={(val) => setUrl(val.target.value)}
                                            />
                                            <span className="validation-text"></span>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 col-sm-10 col-md-6">
                                        <label className="text-black font-w500">Email</label>
                                        <div className="contact-name">
                                            <input type="text" className="form-control"
                                                   name="email" required="required"
                                                   defaultValue={email}
                                                   maxLength={45}
                                                   onChange={(val) => setEmail(val.target.value)}
                                            />
                                            <span className="validation-text"></span>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 col-sm-10 col-md-6">
                                        <label className="text-black font-w500">Address</label>
                                        <div className="contact-name">
                                            <input type="text" className="form-control"
                                                   name="address" required="required"
                                                   defaultValue={address}
                                                   maxLength={40}
                                                   onChange={(val) => setAddress(val.target.value)}
                                            />
                                            <span className="validation-text"></span>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 col-sm-10 col-md-6">
                                        <label className="text-black font-w500">Postal code</label>
                                        <div className="contact-name">
                                            <input type="text" className="form-control text-uppercase"
                                                   name="zipcode" required="required"
                                                   defaultValue={postal_code}
                                                   maxLength={6}
                                                   onChange={(val) => setPostal_code(val.target.value.toString().toUpperCase())}
                                            />
                                            <span className="validation-text"></span>
                                        </div>
                                    </div>

                                    <div className="form-group mb-3 col-sm-10 col-md-6">
                                        <label className="text-black font-w500">City</label>
                                        <div className="contact-name">
                                            <input type="text" className="form-control"
                                                   name="city" required="required"
                                                   defaultValue={city}
                                                   onChange={(val) => setCity(val.target.value)}
                                            />
                                            <span className="validation-text"></span>
                                        </div>
                                    </div>
                                </div>

                            </div>

                    </div>
                    <div className="modal-footer">

                        {/*<button type="button" onClick={() => {}} className="btn btn-danger">*/}
                        {/*    <i className="flaticon-delete-1"></i> Wijzigingen negeren*/}
                        {/*</button>*/}
                        <button type="button" className="btn btn-secondary"
                                onClick={() => {createCarAdmin()}}>Toevoegen
                        </button>
                    </div>
                </div>
            </div>

        </>
    )

    function launchLoadingModal() {
        // Show loading Swal
        Swal.fire({
            title: "Loading...",
            allowOutsideClick: true,
            allowEscapeKey: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
                Swal.showLoading()
            },
        });
    }

    function createCarAdmin(){
        let data = {email, license_plate, url, address, postal_code, city}
        launchLoadingModal()
        createCarAdminCall(data).then(res => {
            window.location = "/orders-overview"
            setLoading(false);
            toast.success(res.data.message, toastOptions);
        }).catch(error => {
            toast.error(error.response.data.error, toastOptions);
        })

    }

}
